import React from 'react';

function FeaturedServices(props) {
    return (
        <section id="featured-services" class="featured-services">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="icon-box">
                            <div class="icon"><i class="bi bi-laptop"></i></div>
                            <h4 class="title"><a>Magento Store Development</a></h4>
                            <p class="description">Provide comprehensive Magento store development services, including setting up the platform, configuring essential features and functionalities, and customizing the design to align with the client's brand identity. This service will involve creating a visually appealing and user-friendly online store that is optimized for conversions.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
                        <div class="icon-box">
                            <div class="icon"><i class="bi bi-card-checklist"></i></div>
                            <h4 class="title"><a>Extension Integration and Customization</a></h4>
                            <p class="description">Help clients extend the functionality of their Magento stores by integrating and customizing relevant extensions. This includes selecting and integrating payment gateways, shipping providers, and other extensions that enhance the customer experience and streamline business operations. Customize these extensions to meet specific business needs and ensure seamless integration with the Magento platform.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
                        <div class="icon-box">
                            <div class="icon"><i class="bi bi-clipboard-data"></i></div>
                            <h4 class="title"><a>Magento Performance Optimization</a></h4>
                            <p class="description">Optimize the performance of Magento stores to ensure fast page loading times and smooth navigation. This service involves analyzing and optimizing code, implementing caching strategies, and fine-tuning server configurations to maximize the store's speed and responsiveness. Improved performance leads to better user experiences, higher conversion rates, and improved search engine rankings.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeaturedServices;