import React, { useEffect } from 'react';
import Header from '../Components/Header';
import Herosection from '../Components/Herosection';
import FeaturedServices from '../Components/FeaturedServices';
import About from '../Components/About';
import Services from '../Components/Services';
import Ctabutton from '../Components/Ctabutton';
import Contact from '../Components/Contact';
import Footer from '../Components/Footer';

function Home(props) {
    return (
        <div>
            <Header />
            <Herosection />
            <FeaturedServices />
            <About />
            <Services />
            <Ctabutton />
            <Contact />
            <Footer />
            <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
        </div>
    );
}

export default Home;