import React from 'react';
import { ref, set } from "firebase/database";
import { database } from "../ComponentFunctions/Firebase";

function Footer(props) {
    const subscribeButton = () => {
        var email = document.getElementById("email_subscribe").value;
        set(ref(database, 'subscribe/' + Date.now()), {
            email: email
        });
        let element = document.getElementById("subcribe_msg_text");
        element.style.display = "block";
        let element2 = document.getElementById("subcribe_msg_form");
        element2.style.display = "none";
    }
    return (
        <footer id="footer">
            <div class="footer-top">
                <div class="container">
                    <div class="row  justify-content-center">
                        <div class="col-lg-6">
                            <h3>MagentoWay</h3>
                            <p>Unleash Your E-commerce Potential with Magento Way</p>
                        </div>
                    </div>
                    <div class="row footer-newsletter justify-content-center">
                        <div class="col-lg-6" id="subcribe_msg_form">
                            <div class='footer_form'>
                                <input type="email" name="email" id="email_subscribe" placeholder="Enter your Email" />
                                <input type="submit" value="Subscribe" onClick={subscribeButton} />
                            </div>
                        </div>
                        <div class="col-lg-6" id="subcribe_msg_text" style={{display:"none"}}>
                            <div>
                                <p style={{fontSize:"18px"}}>Thank you for Subscribing</p>
                            </div>
                        </div>
                    </div>
                    <div class="social-links">
                        {/* <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a> */}
                        {/* <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a> */}
                        {/* <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a> */}
                        {/* <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a> */}
                        {/* <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> */}
                    </div>
                </div>
            </div>
            <div class="container footer-bottom clearfix">
                <div class="copyright">
                    &copy; Copyright <strong><span>MagentoWay</span></strong>. All Rights Reserved
                </div>
                <div class="credits">
                    Designed by MagentoWay
                </div>
            </div>
        </footer>
    );
}

export default Footer;