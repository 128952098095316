import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './Pages/Home';

function App() {
  return (
    // <div className="App">
    //   <Header/>
    // </div>

    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/contact" element={<Contact />} /> */}
      {/* <Route path="/about" element={<About />} /> */}
      {/* <Route path="/post" element={<Post />} /> */}
      {/* <Route path="/post/:id" element={<Post />} /> */}
      {/* <Route path="/category" element={<Category />} /> */}
      {/* <Route path="/category/:type" element={<Category />} /> */}
      {/* <Route path="*" element={<NotFoundPage />} /> */}
    </Routes>
  </Router>
  );
}

export default App;
