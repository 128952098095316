import React from 'react';
import { getDatabase, ref, set } from "firebase/database";
import { database } from "../ComponentFunctions/Firebase";

function Contact(props) {

  const saveContact = () => {
    var name = document.getElementById("name").value;
    var email = document.getElementById("email").value;
    var subject = document.getElementById("subject").value;
    var message = document.getElementById("message").value;
    console.log(name + email + subject + message);
    set(ref(database, 'Contactus/' + Date.now()), {
      name: name,
      email: email,
      subject: subject,
      message: message
    });
    let element = document.getElementById("notice_for_form");
    element.style.display = "block";
    let element2 = document.getElementById("contact_us_form");
    element2.style.display = "none";

  }
  return (
    <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <span>Contact</span>
          <h2>Contact</h2>
          <p>Reach out to us at MagnetoWay for innovative Magento solutions and exceptional service. Let's collaborate and unlock your e-commerce success today!</p>
        </div>

        <div class="row">

          <div class="col-lg-5 d-flex align-items-stretch">
            <div class="info">
              <a href="mailto:contact@magentoway.com">
                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>contact@magentoway.com</p>
                </div>
              </a>
              <a href="https://wa.me/+919022523093">
                <div class="phone">
                  <i class="bi bi-whatsapp"></i>
                  <h4>Call:</h4>
                  <p>+91 90225 23093</p>
                </div>
              </a>


              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe> */}
            </div>

          </div>

          <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <div class="php-email-form">
              <div id="contact_us_form">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="name">Your Name</label>
                    <input type="text" name="name" class="form-control" id="name" required />
                  </div>
                  <div class="form-group col-md-6 mt-3 mt-md-0">
                    <label for="name">Your Email</label>
                    <input type="email" class="form-control" name="email" id="email" required />
                  </div>
                </div>
                <div class="form-group mt-3">
                  <label for="name">Subject</label>
                  <input type="text" class="form-control" name="subject" id="subject" required />
                </div>
                <div class="form-group mt-3">
                  <label for="name">Message</label>
                  <textarea class="form-control" name="message" rows="10" id="message" required></textarea>
                </div>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message" id="notice_for_form">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit" onClick={saveContact} >Send Message</button></div>
            </div>
          </div>

        </div>

      </div>
    </section>
  );
}

export default Contact;