
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyChP4WW_4auiU_zSZZqQZOKl_ayQGXDA58",
    authDomain: "magentoway1.firebaseapp.com",
    databaseURL: "https://magentoway1-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "magentoway1",
    storageBucket: "magentoway1.appspot.com",
    messagingSenderId: "953947388048",
    appId: "1:953947388048:web:64c18986de594f54ce8015",
    measurementId: "G-GHGYT9CN94"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export {database};