import React from 'react';

function Ctabutton(props) {
    return (
        <section id="cta" class="cta">
      <div class="container">
        <div class="text-center">
          <h3>Call To Action</h3>
          <p> "Ready to elevate your online business with Magento? Let's embark on your e-commerce journey together. Contact Magentoway today and experience the power of tailored Magento solutions that drive growth, engage customers, and maximize your online potential. Get started now!"</p>
          <a class="cta-btn" href="tel:+919022523093">Call To Action</a>
        </div>

      </div>
    </section>
    );
}

export default Ctabutton;