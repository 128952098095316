import React from 'react';
import heroimage from '../assets/img/hero-img.png';

function Herosection(props) {
    return (
        <section id="main" class="d-flex align-items-center">

        <div class="container">
          <div class="row">
            <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h1>Elegant and creative solutions</h1>
              <h2>Revolutionize Your Business with SoftnTea</h2>
              <div class="d-flex">
                <a href="#services" class="btn-get-started scrollto">Get Started</a>
              </div>
            </div>
            <div class="col-lg-6 order-1 order-lg-2 hero-img">
              <img src={heroimage} class="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
    
      </section>
    );
}

export default Herosection;