import React from 'react';

function Services(props) {
    return (
        <section id="services" class="services section-bg">
            <div class="container">

                <div class="section-title">
                    <span>Services</span>
                    <h2>Services</h2>
                    <p></p>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="icon-box">
                            <div class="icon"><i class="bx bxl-dribbble"></i></div>
                            <h4><a>Magento Store Development</a></h4>
                            <p>Crafting customized and visually appealing Magento stores that align with your brand and deliver exceptional user experiences.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
                        <div class="icon-box">
                            <div class="icon"><i class="bx bx-file"></i></div>
                            <h4><a>Mobile Commerce Solutions</a></h4>
                            <p>Developing responsive and mobile-friendly Magento stores or native mobile apps for iOS and Android platforms.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                        <div class="icon-box">
                            <div class="icon"><i class="bx bx-tachometer"></i></div>
                            <h4><a>Website Development</a></h4>
                            <p>Developing Cross-platform Supported responsive Websites</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                        <div class="icon-box">
                            <div class="icon"><i class="bx bx-world"></i></div>
                            <h4><a>Extension Integration and Customization</a></h4>
                            <p>Experienced in integrating and customizing Magento extensions to enhance store functionality and meet specific business needs.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                        <div class="icon-box">
                            <div class="icon"><i class="bx bx-slideshow"></i></div>
                            <h4><a>Magento Performance Optimization</a></h4>
                            <p>Capable of optimizing Magento stores for maximum speed, performance, and user experience.</p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4">
                        <div class="icon-box">
                            <div class="icon"><i class="bx bx-arch"></i></div>
                            <h4><a>Magento SEO and Digital Marketing Integration</a></h4>
                            <p>Integrating Magento stores with SEO strategies and digital marketing tools to boost online visibility and drive targeted traffic.</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
}

export default Services;