import React from 'react';
import aboutimage from '../assets/img/about.png';

function About(props) {
  return (
    <section id="about" class="about">
      <div class="container">
        <div class="section-title">
          <span>About Us</span>
          <h2>About Us</h2>
          <p></p>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <img src={aboutimage} class="img-fluid" alt="" />
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0 content">
            <h3>Revolutionize Your Online Business with SoftnTea</h3>
            <p class="fst-italic">
              At SoftnTea, we are passionate about revolutionizing the world of e-commerce by providing innovative solutions and exceptional services. As a leading startup in the industry, we specialize in harnessing the power of Magento, one of the most robust and flexible e-commerce platforms available.
            </p>
            <ul>
              <li><i class="bi bi-check-circle"></i> Expertise and Experience, Tailored Solutions</li>
              <li><i class="bi bi-check-circle"></i> Seamless Integration and Customization, Performance and Optimization</li>
              <li><i class="bi bi-check-circle"></i> Most Important one! Support and Maintenance</li>
            </ul>
            <p>Let's Start Building Your Success Story</p>
            <p>
              Are you ready to unlock the full potential of Magento for your online business? Contact Magentoway today to discuss your e-commerce aspirations. Our team of dedicated professionals is eager to collaborate with you, delivering innovative and reliable Magento solutions that drive growth, engage customers, and achieve your business objectives.
            </p>
          </div>
        </div>

      </div>
    </section>
  );
}

export default About;