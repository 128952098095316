import React, { useState } from 'react';

function Header(props) {
  const [mobileclass, setMobileClass] = useState(false);

  const setMenuItemClick =()=>{
    let itag=document.getElementById("mobile_menu");
    console.log(itag);
    console.log(itag.classList);
    let result=itag.classList.contains("bi-x");
    console.log(result);
    if(result){
      setMobileClass(!mobileclass);
    }
  }
    return (
        <header id="header" class="fixed-top">
        <div class="container d-flex align-items-center justify-content-between">
          <h1 class="logo"><a href="/">SoftnTea</a></h1>
          <nav id="navbar" className={`navbar ${mobileclass ? 'navbar-mobile' : ''}`}>
            <ul>
              <li><a class="nav-link scrollto" href="#main" onClick={() => setMenuItemClick()}>Home</a></li>
              <li><a class="nav-link scrollto" href="#about" onClick={() => setMenuItemClick()}>About</a></li>
              <li><a class="nav-link scrollto" href="#services" onClick={() => setMenuItemClick()}>Services</a></li>
              <li><a class="nav-link scrollto" href="#contact" onClick={() => setMenuItemClick()}>Contact Us</a></li>
            </ul>
            <i id="mobile_menu" className={`bi mobile-nav-toggle ${mobileclass ? 'bi-x' : 'bi-list'}`} onClick={() => setMobileClass(!mobileclass)}></i>
          </nav>
        </div>
      </header>
    );
}

export default Header;